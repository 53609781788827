var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "a-radio-group",
        {
          on: { change: _vm.radioChange },
          model: {
            value: _vm.viewType,
            callback: function ($$v) {
              _vm.viewType = $$v
            },
            expression: "viewType",
          },
        },
        [
          _vm.kpiShow
            ? _c("a-radio", { attrs: { value: 1 } }, [
                _vm._v("\n      考核\n    "),
              ])
            : _vm._e(),
          _vm.kpiList.length
            ? _c("a-radio", { attrs: { value: 2 } }, [
                _vm._v("\n      对其他阶段考核\n    "),
              ])
            : _vm._e(),
          _vm.moneyShow || _vm.taskSettles.length
            ? _c("a-radio", { attrs: { value: 3 } }, [
                _vm._v("\n      结算\n    "),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c("a-spin", { attrs: { spinning: _vm.spinning } }, [
        _vm.viewType == 1 && _vm.formData.kpiValueStages
          ? _c(
              "div",
              {
                staticClass: "kpi-show",
                staticStyle: {
                  width: "100%",
                  "padding-top": "20px",
                  height: "62.2vh",
                  "overflow-y": "overlay",
                },
              },
              [
                _vm._l(_vm.formData.kpiValueStages, function (kpi) {
                  return _c(
                    "div",
                    { key: kpi.id, staticStyle: { "padding-bottom": "40px" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "coef_item",
                          staticStyle: { display: "flex" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-weight": "bold",
                                "font-size": "14px",
                              },
                            },
                            [
                              _vm._v(
                                "【" +
                                  _vm._s(kpi.stage.stageName) +
                                  "】对当前阶段考核结果"
                              ),
                            ]
                          ),
                        ]
                      ),
                      (kpi.kpiTypeValuesMap.KPI_ERRORS
                        ? kpi.kpiTypeValuesMap.KPI_ERRORS.some(
                            (error) =>
                              error.value !== null || error.value !== undefined
                          )
                        : false) ||
                      (kpi.kpiTypeValuesMap.KPI_MODIFY
                        ? kpi.kpiTypeValuesMap.KPI_ERRORS.some(
                            (error) =>
                              error.value !== null || error.value !== undefined
                          )
                        : false)
                        ? [
                            _vm._l(
                              kpi.kpiTypeValuesMap.KPI_ERRORS,
                              function (error) {
                                return [
                                  error.value !== null &&
                                  error.value !== undefined
                                    ? _c(
                                        "div",
                                        {
                                          key: error.id,
                                          staticClass: "coef_item",
                                          staticStyle: {
                                            display: "flex",
                                            "padding-top": "13px",
                                            "justify-content": "space-between",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: { display: "flex" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "font-size": "14px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        error.errorType ==
                                                          "SOFT"
                                                          ? "软伤数"
                                                          : error.errorType ==
                                                            "HARD"
                                                          ? "硬伤数"
                                                          : error.errorType ==
                                                            "ERROR"
                                                          ? "错翻数"
                                                          : error.errorType ==
                                                            "SUPER_HARD"
                                                          ? "超级硬伤数"
                                                          : error.errorType ==
                                                            "V1_HARD"
                                                          ? "1改硬伤数"
                                                          : error.errorType ==
                                                            "V2_HARD"
                                                          ? "2改硬伤数"
                                                          : error.errorType ==
                                                            "MORE_THAN_V2_HARD"
                                                          ? "2改以上硬伤数"
                                                          : error.errorType ==
                                                            "SUPER_SOFT"
                                                          ? "超级软伤"
                                                          : ""
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "font-size": "14px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    error.value === null ||
                                                      error.value === undefined
                                                      ? "/   "
                                                      : error.value
                                                  ) +
                                                  "个\n              "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              }
                            ),
                            _vm._l(
                              kpi.kpiTypeValuesMap.KPI_MODIFY,
                              function (error) {
                                return [
                                  error.value !== null &&
                                  error.value !== undefined
                                    ? _c(
                                        "div",
                                        {
                                          key: error.id,
                                          staticClass: "coef_item",
                                          staticStyle: {
                                            display: "flex",
                                            "padding-top": "13px",
                                            "justify-content": "space-between",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: { display: "flex" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "font-size": "14px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  修改量\n                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "font-size": "14px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    error.value === null ||
                                                      error.value === undefined
                                                      ? "/   "
                                                      : error.value
                                                  ) +
                                                  "个\n              "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              }
                            ),
                          ]
                        : _c(
                            "div",
                            {
                              staticStyle: {
                                "padding-top": "20px",
                                color: "rgba(0,0,0,0.25)",
                              },
                            },
                            [_vm._v("\n          无\n        ")]
                          ),
                    ],
                    2
                  )
                }),
                _c(
                  "div",
                  [
                    _c(
                      "div",
                      {
                        staticClass: "coef_item",
                        staticStyle: { display: "flex" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-weight": "bold",
                              "font-size": "14px",
                            },
                          },
                          [_vm._v("\n            考核汇总\n          ")]
                        ),
                      ]
                    ),
                    _vm.formData.hasKpiDeliveries
                      ? _c(
                          "div",
                          {
                            staticClass: "coef_item",
                            staticStyle: {
                              display: "flex",
                              "padding-top": "15px",
                              "justify-content": "space-between",
                              "font-size": "14px",
                            },
                          },
                          [
                            _c("div", { staticStyle: { display: "flex" } }, [
                              _c("div", { staticStyle: { width: "120px" } }, [
                                _vm._v(
                                  "\n              完成情况\n            "
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              {
                                style: _vm.isDesktop()
                                  ? "min-width:90px"
                                  : "min-width:45px",
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.formData.deliveryStatus == "ADVANCE"
                                        ? "提前"
                                        : _vm.formData.deliveryStatus ==
                                          "ON_TIME"
                                        ? "按时"
                                        : _vm.formData.deliveryStatus == "DELAY"
                                        ? "拖稿"
                                        : ""
                                    ) +
                                    "\n          "
                                ),
                              ]
                            ),
                            _c("div", [
                              _c("div", {
                                style: _vm.isDesktop()
                                  ? "min-width:90px"
                                  : "min-width:45px",
                              }),
                            ]),
                            _c("div", [
                              _c(
                                "div",
                                {
                                  style: _vm.isDesktop()
                                    ? "min-width:60px;text-align:right"
                                    : "min-width:30px;text-align:right",
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.formData.deliveryScore === null ||
                                          _vm.formData.deliveryScore ===
                                            undefined
                                          ? "/   "
                                          : _vm.formData.deliveryScore
                                      ) +
                                      "分\n            "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm.formData.hasKpiErrors
                      ? _c(
                          "div",
                          {
                            staticClass: "coef_item",
                            staticStyle: {
                              display: "flex",
                              "padding-top": "15px",
                              "justify-content": "space-between",
                              "font-size": "14px",
                            },
                          },
                          [
                            _c("div", { staticStyle: { display: "flex" } }, [
                              _c("div", { staticStyle: { width: "120px" } }, [
                                _vm._v("\n              软硬伤\n            "),
                              ]),
                            ]),
                            _c("div", [
                              _c(
                                "div",
                                {
                                  style: _vm.isDesktop()
                                    ? "min-width:60px;text-align:right"
                                    : "min-width:30px;text-align:right",
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.formData.kpiErrorScore !== null
                                          ? _vm.formData.kpiErrorScore + "分"
                                          : "/   "
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm.formData.hasKpiErrors
                      ? _vm._l(_vm.formData.kpiValueErrors, function (kpi) {
                          return _c(
                            "div",
                            {
                              key: kpi.id,
                              staticClass: "coef_item",
                              staticStyle: {
                                display: "flex",
                                "padding-top": "15px",
                                "justify-content": "space-between",
                                "font-size": "0.vw",
                              },
                            },
                            [
                              _c("div", { staticStyle: { display: "flex" } }, [
                                _c("div", { staticStyle: { width: "120px" } }, [
                                  _vm._v(
                                    "\n                -" +
                                      _vm._s(
                                        kpi.type == "SOFT"
                                          ? "软伤数"
                                          : kpi.type == "HARD"
                                          ? "硬伤数"
                                          : kpi.type == "ERROR"
                                          ? "错翻数"
                                          : kpi.type == "SUPER_HARD"
                                          ? "超级硬伤数"
                                          : kpi.type == "V1_HARD"
                                          ? "1改硬伤数"
                                          : kpi.type == "V2_HARD"
                                          ? "2改硬伤数"
                                          : kpi.type == "MORE_THAN_V2_HARD"
                                          ? "2改以上硬伤数"
                                          : kpi.type == "SUPER_SOFT"
                                          ? "超级软伤"
                                          : ""
                                      ) +
                                      "\n              "
                                  ),
                                ]),
                              ]),
                              _c(
                                "div",
                                {
                                  style: _vm.isDesktop()
                                    ? "min-width:90px"
                                    : "min-width:45px",
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        kpi.count === null ||
                                          kpi.count === undefined
                                          ? "/   "
                                          : kpi.count
                                      ) +
                                      "个\n            "
                                  ),
                                ]
                              ),
                              _c("div", [
                                _c(
                                  "div",
                                  {
                                    style: _vm.isDesktop()
                                      ? "min-width:90px"
                                      : "min-width:45px",
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          kpi.score && kpi.score !== "0"
                                            ? "-"
                                            : ""
                                        ) +
                                        _vm._s(
                                          kpi.score !== null &&
                                            kpi.score !== undefined
                                            ? kpi.score
                                            : "/   "
                                        ) +
                                        "分\n              "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", [
                                _c("div", {
                                  style: _vm.isDesktop()
                                    ? "min-width:60px;text-align:right"
                                    : "min-width:30px;text-align:right",
                                }),
                              ]),
                            ]
                          )
                        })
                      : _vm._e(),
                    _vm.formData.hasKpiModifies
                      ? _c(
                          "div",
                          {
                            staticClass: "coef_item",
                            staticStyle: {
                              display: "flex",
                              "padding-top": "15px",
                              "justify-content": "space-between",
                              "font-size": "14px",
                            },
                          },
                          [
                            _c("div", { staticStyle: { display: "flex" } }, [
                              _c("div", { staticStyle: { width: "120px" } }, [
                                _vm._v("\n              修改率\n            "),
                              ]),
                            ]),
                            _c(
                              "div",
                              {
                                style: _vm.isDesktop()
                                  ? "min-width:90px"
                                  : "min-width:45px",
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.formData.kpiModifyAmount === null ||
                                        _vm.formData.kpiModifyAmount ===
                                          undefined
                                        ? "/   "
                                        : _vm.formData.kpiModifyAmount
                                    ) +
                                    "个\n          "
                                ),
                              ]
                            ),
                            _c("div", [
                              _c(
                                "div",
                                {
                                  style: _vm.isDesktop()
                                    ? "min-width:90px"
                                    : "min-width:45px",
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.formData.kpiModifyType == "LOW"
                                          ? (_vm.formData.kpiModifyRatio ||
                                              "0") +
                                              "%" +
                                              "（低）"
                                          : _vm.formData.kpiModifyType ==
                                            "MEDIUM"
                                          ? (_vm.formData.kpiModifyRatio ||
                                              "0") +
                                            "%" +
                                            "（中）"
                                          : _vm.formData.kpiModifyType == "HIGH"
                                          ? (_vm.formData.kpiModifyRatio ||
                                              "0") +
                                            "%" +
                                            "（高）"
                                          : (_vm.formData.kpiModifyRatio ||
                                              "0") + "%"
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]),
                            _c("div", [
                              _c(
                                "div",
                                {
                                  style: _vm.isDesktop()
                                    ? "min-width:60px;text-align:right;position:relative;font-size:14px"
                                    : "min-width:30px;text-align:right;position:relative;font-size:14px",
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.formData.kpiModifyScore === null ||
                                          _vm.formData.kpiModifyScore ===
                                            undefined
                                          ? "/   "
                                          : _vm.formData.kpiModifyScore
                                      ) +
                                      "分\n            "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ],
                  2
                ),
                _c("div", { staticStyle: { "padding-bottom": "40px" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "coef_item",
                      staticStyle: {
                        display: "flex",
                        "padding-top": "15px",
                        "justify-content": "space-between",
                        "font-weight": "bold",
                      },
                    },
                    [
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _c("div", { staticStyle: { "font-size": "14px" } }, [
                          _vm._v("总分"),
                        ]),
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "14px",
                            position: "reletive",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "min-width": "60px",
                                "text-align": "right",
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.formData.totalScore === null ||
                                      _vm.formData.totalScore === undefined
                                      ? "/   "
                                      : _vm.formData.totalScore
                                  ) +
                                  "分\n            "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ],
              2
            )
          : _vm._e(),
        _vm.viewType == 2
          ? _c(
              "div",
              { staticStyle: { width: "80%", "padding-top": "20px" } },
              _vm._l(_vm.kpiList, function (item) {
                return _c(
                  "div",
                  { key: item.id, staticStyle: { "padding-bottom": "40px" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "coef_item",
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-weight": "bold",
                              "font-size": "14px",
                            },
                          },
                          [
                            _vm._v(
                              "当前阶段对【" +
                                _vm._s(item.stage.stageName) +
                                "】阶段考核结果"
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "a-button",
                              {
                                attrs: {
                                  type: "primary",
                                  icon: item.kpiValues[0].isEdit
                                    ? "save"
                                    : "edit",
                                },
                                on: { click: _vm.changeKpi },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    item.kpiValues[0].isEdit ? "保存" : "编辑"
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._l(
                      item.kpiValues.filter((kpi) => {
                        return (
                          !_vm.taskDetail.isLocal ||
                          (_vm.taskDetail.stageName == "监制" &&
                            (kpi.errorType == "SUPER_HARD" ||
                              kpi.errorType == "HARD" ||
                              kpi.errorType == "SUPER_SOFT" ||
                              kpi.errorType == "ERROR" ||
                              kpi.errorType == "SOFT")) ||
                          (_vm.taskDetail.stageName == "监制确认" &&
                            (kpi.errorType == "V1_HARD" ||
                              kpi.errorType == "V2_HARD" ||
                              kpi.errorType == "MORE_THAN_V2_HARD")) ||
                          (_vm.taskDetail.stageName != "监制确认" &&
                            _vm.taskDetail.stageName != "监制")
                        )
                      }),
                      function (kpi) {
                        return [
                          kpi.kpiType == "KPI_ERRORS"
                            ? _c(
                                "div",
                                {
                                  key: kpi.id,
                                  staticClass: "coef_item",
                                  staticStyle: {
                                    display: "flex",
                                    "padding-top": "13px",
                                    "justify-content": "space-between",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: { "font-size": "14px" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                kpi.errorType == "SOFT"
                                                  ? "软伤数"
                                                  : kpi.errorType == "HARD"
                                                  ? "硬伤数"
                                                  : kpi.errorType == "ERROR"
                                                  ? "错翻数"
                                                  : kpi.errorType ==
                                                    "SUPER_HARD"
                                                  ? "超级硬伤数"
                                                  : kpi.errorType == "V1_HARD"
                                                  ? "1改硬伤数"
                                                  : kpi.errorType == "V2_HARD"
                                                  ? "2改硬伤数"
                                                  : kpi.errorType ==
                                                    "MORE_THAN_V2_HARD"
                                                  ? "2改以上硬伤数"
                                                  : kpi.errorType ==
                                                    "SUPER_SOFT"
                                                  ? "超级软伤"
                                                  : ""
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticStyle: { "font-size": "14px" } },
                                    [
                                      kpi.isEdit
                                        ? _c(
                                            "div",
                                            [
                                              _c("a-input-number", {
                                                staticStyle: {
                                                  width: "45px",
                                                  "padding-left": "4px",
                                                  "padding-right": "4px",
                                                  "font-size": "14px",
                                                  "padding-top": "0",
                                                  "padding-bottom": "0",
                                                },
                                                attrs: { min: 0 },
                                                on: {
                                                  change: (e) => {
                                                    _vm.inputChange(e, kpi)
                                                  },
                                                },
                                                model: {
                                                  value: kpi.value,
                                                  callback: function ($$v) {
                                                    _vm.$set(kpi, "value", $$v)
                                                  },
                                                  expression: "kpi.value",
                                                },
                                              }),
                                              _vm._v("个\n              "),
                                            ],
                                            1
                                          )
                                        : _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                kpi.value == null
                                                  ? "/   "
                                                  : kpi.value
                                              ) + "个"
                                            ),
                                          ]),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      }
                    ),
                    _vm._l(item.kpiValues, function (kpi) {
                      return [
                        kpi.kpiType != "KPI_ERRORS" &&
                        _vm.taskDetail.stageName != "监制确认"
                          ? _c(
                              "div",
                              {
                                key: kpi.id,
                                staticClass: "coef_item",
                                staticStyle: {
                                  display: "flex",
                                  "padding-top": "13px",
                                  "justify-content": "space-between",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { display: "flex" } },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { "font-size": "14px" } },
                                      [
                                        _vm._v(
                                          "\n                修改量\n              "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticStyle: { "font-size": "14px" } },
                                  [
                                    kpi.isEdit
                                      ? _c(
                                          "div",
                                          [
                                            _c("a-input-number", {
                                              staticStyle: {
                                                width: "45px",
                                                "padding-left": "4px",
                                                "padding-right": "4px",
                                                "font-size": "14px",
                                                "padding-top": "0",
                                                "padding-bottom": "0",
                                              },
                                              attrs: { min: 0 },
                                              on: {
                                                change: (e) => {
                                                  _vm.inputChange(e, kpi)
                                                },
                                              },
                                              model: {
                                                value: kpi.value,
                                                callback: function ($$v) {
                                                  _vm.$set(kpi, "value", $$v)
                                                },
                                                expression: "kpi.value",
                                              },
                                            }),
                                            _vm._v("个\n              "),
                                          ],
                                          1
                                        )
                                      : _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              kpi.value == null
                                                ? "/   "
                                                : kpi.value
                                            ) + "个"
                                          ),
                                        ]),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                )
              }),
              0
            )
          : _vm._e(),
        _vm.viewType == 3
          ? _c(
              "div",
              { staticStyle: { width: "80%", "padding-top": "20px" } },
              [
                _c("div", { staticStyle: { "padding-bottom": "40px" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "coef_item",
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-weight": "bold",
                            "font-size": "14px",
                          },
                        },
                        [_vm._v("结算")]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "coef_item",
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                        "padding-top": "15px",
                      },
                    },
                    [
                      _c("div", { staticStyle: { "font-size": "14px" } }, [
                        _vm._v(
                          "\n            实际应付（" +
                            _vm._s(
                              _vm.rule.priceCalcType == "FIXED"
                                ? _vm.moneyTypeMap[_vm.rule.moneyType]
                                : _vm.rule.levelRules &&
                                    _vm.moneyTypeMap[
                                      _vm.rule.levelRules[0].score.unit
                                    ]
                            ) +
                            "）\n          "
                        ),
                      ]),
                      _c("div", {
                        staticStyle: {
                          "font-size": "14px",
                          position: "relative",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "coef_item",
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                        "padding-top": "15px",
                      },
                    },
                    [
                      _c("div", { staticStyle: { "font-size": "14px" } }, [
                        _vm._v(
                          "\n            =" +
                            _vm._s(
                              _vm.rule.priceCalcType == "FIXED"
                                ? " 固定价格 × 结算数量"
                                : "人员等级单价基数 × 系数/难易程度系数  × 结算数量"
                            ) +
                            "\n          "
                        ),
                      ]),
                      _c("div", {
                        staticStyle: {
                          "font-size": "14px",
                          position: "relative",
                        },
                      }),
                    ]
                  ),
                  _vm.taskSettles.length
                    ? _c(
                        "div",
                        {
                          staticClass: "coef_item",
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-between",
                            "padding-top": "15px",
                          },
                        },
                        [
                          _c("div", { staticStyle: { "font-size": "14px" } }, [
                            _vm._v(
                              "\n            =" +
                                _vm._s(
                                  _vm.taskSettles[0].levelBasePrice
                                    ? _vm.taskSettles[0].levelBasePrice +
                                        "×" +
                                        _vm.taskSettles[0].factor +
                                        "×" +
                                        _vm.taskSettles[0].kpiScoreFactor
                                    : _vm.taskSettles[0].unitPrice
                                ) +
                                "\n          "
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "14px",
                                position: "relative",
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.taskSettles[0].resultPrice) +
                                  _vm._s(
                                    _vm.rule.levelRules &&
                                      _vm.moneyTypeMap[
                                        _vm.rule.levelRules[0].score.unit
                                      ]
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }