<template>
  <div>
    <a-radio-group v-model="viewType" @change="radioChange">
      <a-radio :value="1" v-if="kpiShow">
        考核
      </a-radio>
      <a-radio :value="2" v-if="kpiList.length">
        对其他阶段考核
      </a-radio>
      <a-radio :value="3" v-if="moneyShow || taskSettles.length">
        结算
      </a-radio>
    </a-radio-group>
    <a-spin :spinning="spinning">
      <div
        v-if="viewType == 1 && formData.kpiValueStages"
        class="kpi-show"
        style="width:100%;padding-top:20px;height:62.2vh;overflow-y: overlay;"
      >
        <div style="padding-bottom:40px;" v-for="kpi in formData.kpiValueStages" :key="kpi.id">
          <div class="coef_item" style="display:flex">
            <div style="font-weight:bold;font-size:14px">【{{ kpi.stage.stageName }}】对当前阶段考核结果</div>
          </div>
          <template
            v-if="
              (kpi.kpiTypeValuesMap.KPI_ERRORS
                ? kpi.kpiTypeValuesMap.KPI_ERRORS.some(error => error.value !== null || error.value !== undefined)
                : false) ||
                (kpi.kpiTypeValuesMap.KPI_MODIFY
                  ? kpi.kpiTypeValuesMap.KPI_ERRORS.some(error => error.value !== null || error.value !== undefined)
                  : false)
            "
          >
            <template v-for="error in kpi.kpiTypeValuesMap.KPI_ERRORS">
              <div
                class="coef_item"
                style="display:flex;padding-top:13px;justify-content:space-between;"
                v-if="error.value !== null && error.value !== undefined"
                :key="error.id"
              >
                <div style="display:flex">
                  <div style="font-size:14px">
                    {{
                      error.errorType == 'SOFT'
                        ? '软伤数'
                        : error.errorType == 'HARD'
                        ? '硬伤数'
                        : error.errorType == 'ERROR'
                        ? '错翻数'
                        : error.errorType == 'SUPER_HARD'
                        ? '超级硬伤数'
                        : error.errorType == 'V1_HARD'
                        ? '1改硬伤数'
                        : error.errorType == 'V2_HARD'
                        ? '2改硬伤数'
                        : error.errorType == 'MORE_THAN_V2_HARD'
                        ? '2改以上硬伤数'
                        : error.errorType == 'SUPER_SOFT'
                        ? '超级软伤'
                        : ''
                    }}
                  </div>
                </div>
                <div style="font-size:14px">
                  {{ error.value === null || error.value === undefined ? '/   ' : error.value }}个
                </div>
              </div>
            </template>
            <template v-for="error in kpi.kpiTypeValuesMap.KPI_MODIFY">
              <div
                class="coef_item"
                style="display:flex;padding-top:13px;justify-content:space-between;"
                v-if="error.value !== null && error.value !== undefined"
                :key="error.id"
              >
                <div style="display:flex">
                  <div style="font-size:14px">
                    修改量
                  </div>
                </div>
                <div style="font-size:14px">
                  {{ error.value === null || error.value === undefined ? '/   ' : error.value }}个
                </div>
              </div>
            </template>
          </template>
          <div v-else style="padding-top:20px;color:rgba(0,0,0,0.25)">
            无
          </div>
        </div>
        <div>
          <div class="coef_item" style="display:flex">
            <div style="font-weight:bold;font-size:14px">
              考核汇总
            </div>
          </div>
          <div
            v-if="formData.hasKpiDeliveries"
            class="coef_item"
            style="display:flex;padding-top:15px;justify-content:space-between;font-size:14px"
          >
            <div style="display:flex">
              <div style="width:120px">
                完成情况
              </div>
            </div>
            <div :style="isDesktop() ? 'min-width:90px' : 'min-width:45px'">
              {{
                formData.deliveryStatus == 'ADVANCE'
                  ? '提前'
                  : formData.deliveryStatus == 'ON_TIME'
                  ? '按时'
                  : formData.deliveryStatus == 'DELAY'
                  ? '拖稿'
                  : ''
              }}
            </div>
            <div>
              <div :style="isDesktop() ? 'min-width:90px' : 'min-width:45px'"></div>
            </div>
            <div>
              <div :style="isDesktop() ? 'min-width:60px;text-align:right' : 'min-width:30px;text-align:right'">
                {{
                  formData.deliveryScore === null || formData.deliveryScore === undefined
                    ? '/   '
                    : formData.deliveryScore
                }}分
              </div>
            </div>
          </div>
          <div
            v-if="formData.hasKpiErrors"
            class="coef_item"
            style="display:flex;padding-top:15px;justify-content:space-between;font-size:14px"
          >
            <div style="display:flex">
              <div style="width:120px">
                软硬伤
              </div>
            </div>
            <!-- <div style="min-width:90px">
              {{
                formData.deliveryStatus == 'ADVANCE'
                  ? '提前'
                  : formData.deliveryStatus == 'ON_TIME'
                  ? '按时'
                  : formData.deliveryStatus == 'DELAY'
                  ? '拖稿'
                  : ''
              }}
            </div> -->
            <div>
              <div :style="isDesktop() ? 'min-width:60px;text-align:right' : 'min-width:30px;text-align:right'">
                {{ formData.kpiErrorScore !== null ? formData.kpiErrorScore + '分' : '/   ' }}
              </div>
            </div>
          </div>
          <template v-if="formData.hasKpiErrors">
            <div
              v-for="kpi in formData.kpiValueErrors"
              :key="kpi.id"
              class="coef_item"
              style="display:flex;padding-top:15px;justify-content:space-between;font-size:0.vw"
            >
              <div style="display:flex">
                <div style="width:120px">
                  -{{
                    kpi.type == 'SOFT'
                      ? '软伤数'
                      : kpi.type == 'HARD'
                      ? '硬伤数'
                      : kpi.type == 'ERROR'
                      ? '错翻数'
                      : kpi.type == 'SUPER_HARD'
                      ? '超级硬伤数'
                      : kpi.type == 'V1_HARD'
                      ? '1改硬伤数'
                      : kpi.type == 'V2_HARD'
                      ? '2改硬伤数'
                      : kpi.type == 'MORE_THAN_V2_HARD'
                      ? '2改以上硬伤数'
                      : kpi.type == 'SUPER_SOFT'
                      ? '超级软伤'
                      : ''
                  }}
                </div>
              </div>
              <div :style="isDesktop() ? 'min-width:90px' : 'min-width:45px'">
                {{ kpi.count === null || kpi.count === undefined ? '/   ' : kpi.count }}个
              </div>
              <div>
                <div :style="isDesktop() ? 'min-width:90px' : 'min-width:45px'">
                  {{ kpi.score && kpi.score !== '0' ? '-' : ''
                  }}{{ kpi.score !== null && kpi.score !== undefined ? kpi.score : '/   ' }}分
                </div>
              </div>
              <div>
                <div :style="isDesktop() ? 'min-width:60px;text-align:right' : 'min-width:30px;text-align:right'"></div>
              </div>
            </div>
          </template>
          <div
            v-if="formData.hasKpiModifies"
            class="coef_item"
            style="display:flex;padding-top:15px;justify-content:space-between;font-size:14px"
          >
            <div style="display:flex">
              <div style="width:120px">
                修改率
              </div>
            </div>
            <div :style="isDesktop() ? 'min-width:90px' : 'min-width:45px'">
              {{
                formData.kpiModifyAmount === null || formData.kpiModifyAmount === undefined
                  ? '/   '
                  : formData.kpiModifyAmount
              }}个
            </div>
            <div>
              <div :style="isDesktop() ? 'min-width:90px' : 'min-width:45px'">
                {{
                  formData.kpiModifyType == 'LOW'
                    ? (formData.kpiModifyRatio || '0') + '%' + '（低）'
                    : formData.kpiModifyType == 'MEDIUM'
                    ? (formData.kpiModifyRatio || '0') + '%' + '（中）'
                    : formData.kpiModifyType == 'HIGH'
                    ? (formData.kpiModifyRatio || '0') + '%' + '（高）'
                    : (formData.kpiModifyRatio || '0') + '%'
                }}
              </div>
            </div>
            <div>
              <div
                :style="
                  isDesktop()
                    ? 'min-width:60px;text-align:right;position:relative;font-size:14px'
                    : 'min-width:30px;text-align:right;position:relative;font-size:14px'
                "
              >
                {{
                  formData.kpiModifyScore === null || formData.kpiModifyScore === undefined
                    ? '/   '
                    : formData.kpiModifyScore
                }}分
              </div>
            </div>
          </div>
        </div>
        <div style="padding-bottom:40px">
          <div class="coef_item" style="display:flex;padding-top:15px;justify-content:space-between;font-weight:bold">
            <div style="display:flex;">
              <div style="font-size:14px">总分</div>
            </div>
            <div style="font-size:14px;position:reletive">
              <div style="min-width:60px;text-align:right">
                {{ formData.totalScore === null || formData.totalScore === undefined ? '/   ' : formData.totalScore }}分
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="viewType == 2" style="width:80%;padding-top:20px;">
        <div style="padding-bottom:40px;" v-for="item in kpiList" :key="item.id">
          <div class="coef_item" style="display:flex;justify-content:space-between">
            <div style="font-weight:bold;font-size:14px">当前阶段对【{{ item.stage.stageName }}】阶段考核结果</div>
            <div>
              <!-- v-if="systemTypes.every(item => item != 'IMAGE_TRANSLATION_SYSTEM')||(taskDetail.stageName!='翻译'&&taskDetail.stageName!='校对'&&taskDetail.stageName!='终审')" -->
              <a-button type="primary" :icon="item.kpiValues[0].isEdit ? 'save' : 'edit'" @click="changeKpi">{{
                item.kpiValues[0].isEdit ? '保存' : '编辑'
              }}</a-button>
            </div>
          </div>
          <template
            v-for="kpi in item.kpiValues.filter(kpi => {
              return (
                !taskDetail.isLocal ||
                (taskDetail.stageName == '监制' &&
                  (kpi.errorType == 'SUPER_HARD' ||
                    kpi.errorType == 'HARD' ||
                    kpi.errorType == 'SUPER_SOFT' ||
                    kpi.errorType == 'ERROR'||
                    kpi.errorType == 'SOFT')) ||
                (taskDetail.stageName == '监制确认' &&
                  (kpi.errorType == 'V1_HARD' || kpi.errorType == 'V2_HARD' || kpi.errorType == 'MORE_THAN_V2_HARD')) ||
                (taskDetail.stageName != '监制确认' && taskDetail.stageName != '监制')
              )
            })"
          >
            <div
              v-if="kpi.kpiType == 'KPI_ERRORS'"
              :key="kpi.id"
              class="coef_item"
              style="display:flex;padding-top:13px;justify-content:space-between"
            >
              <div style="display:flex">
                <div style="font-size:14px">
                  {{
                    kpi.errorType == 'SOFT'
                      ? '软伤数'
                      : kpi.errorType == 'HARD'
                      ? '硬伤数'
                      : kpi.errorType == 'ERROR'
                      ? '错翻数'
                      : kpi.errorType == 'SUPER_HARD'
                      ? '超级硬伤数'
                      : kpi.errorType == 'V1_HARD'
                      ? '1改硬伤数'
                      : kpi.errorType == 'V2_HARD'
                      ? '2改硬伤数'
                      : kpi.errorType == 'MORE_THAN_V2_HARD'
                      ? '2改以上硬伤数'
                      : kpi.errorType == 'SUPER_SOFT'
                      ? '超级软伤'
                      : ''
                  }}
                </div>
              </div>
              <div style="font-size:14px">
                <div v-if="kpi.isEdit">
                  <a-input-number
                    :min="0"
                    v-model="kpi.value"
                    style="width:45px;padding-left:4px;padding-right:4px;font-size:14px;padding-top:0;padding-bottom:0"
                    @change="
                      e => {
                        inputChange(e, kpi)
                      }
                    "
                  />个
                </div>
                <div v-else>{{ kpi.value == null ? '/   ' : kpi.value }}个</div>
              </div>
            </div>
          </template>
          <template v-for="kpi in item.kpiValues">
            <div
              v-if="kpi.kpiType != 'KPI_ERRORS' && taskDetail.stageName != '监制确认'"
              :key="kpi.id"
              class="coef_item"
              style="display:flex;padding-top:13px;justify-content:space-between"
            >
              <div style="display:flex">
                <div style="font-size:14px">
                  修改量
                </div>
              </div>
              <div style="font-size:14px">
                <div v-if="kpi.isEdit">
                  <a-input-number
                    :min="0"
                    v-model="kpi.value"
                    style="width:45px;padding-left:4px;padding-right:4px;font-size:14px;padding-top:0;padding-bottom:0"
                    @change="
                      e => {
                        inputChange(e, kpi)
                      }
                    "
                  />个
                </div>
                <div v-else>{{ kpi.value == null ? '/   ' : kpi.value }}个</div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div v-if="viewType == 3" style="width:80%;padding-top:20px;">
        <div style="padding-bottom:40px">
          <div class="coef_item" style="display:flex;justify-content:space-between;">
            <div style="font-weight:bold;font-size:14px">结算</div>
            <!--             <div>
              <a-button
                type="primary"
                @click="
                  () => {
                    this.isDis = !this.isDis
                  }
                "
                >{{ isDis ? '保存' : '编辑' }}</a-button
              >
            </div> -->
          </div>
          <div class="coef_item" style="display:flex;justify-content:space-between;padding-top:15px;">
            <div style="font-size:14px">
              实际应付（{{
                rule.priceCalcType == 'FIXED'
                  ? moneyTypeMap[rule.moneyType]
                  : rule.levelRules && moneyTypeMap[rule.levelRules[0].score.unit]
              }}）
            </div>
            <div style="font-size:14px;position:relative"></div>
          </div>
          <div class="coef_item" style="display:flex;justify-content:space-between;padding-top:15px;">
            <div style="font-size:14px">
              ={{
                rule.priceCalcType == 'FIXED'
                  ? ' 固定价格 × 结算数量'
                  : '人员等级单价基数 × 系数/难易程度系数  × 结算数量'
              }}
            </div>
            <div style="font-size:14px;position:relative"></div>
          </div>
          <div
            v-if="taskSettles.length"
            class="coef_item"
            style="display:flex;justify-content:space-between;padding-top:15px;"
          >
            <div style="font-size:14px">
              ={{
                taskSettles[0].levelBasePrice
                  ? taskSettles[0].levelBasePrice + '×' + taskSettles[0].factor + '×' + taskSettles[0].kpiScoreFactor
                  : taskSettles[0].unitPrice
              }}
            </div>
            <div style="font-size:14px;position:relative">
              {{ taskSettles[0].resultPrice }}{{ rule.levelRules && moneyTypeMap[rule.levelRules[0].score.unit] }}
            </div>
          </div>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { postAction, getAction } from '@/api/manage'
import { Empty } from 'ant-design-vue'
import { mixinDevice } from '@/utils/mixin.js'
export default {
  mixins: [mixinDevice],
  props: {
    taskDetail: {
      type: Object,
      default: () => {}
    },
    rule: {
      type: Object,
      default: () => {}
    },
    kpiShow: {
      type: Boolean,
      default: false
    },
    moneyShow: {
      type: Boolean,
      default: false
    },
    systemTypes: {
      type: Array,
      default: () => []
    },
    formData: {
      type: Object,
      default: () => ({
        kpiValueStages: []
      })
    },
    params: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      viewType: 1,
      value: '',
      spinning: false,
      isDis: false,
      kpiList: [],
      kpiDis: false,
      taskSettles: [],
      moneyTypeMap: {
        CNY: '元',
        TWD: '台币',
        JPY: '円',
        USD: '美元'
      }
    }
  },
  beforeCreate() {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE
  },
  mounted() {
    this.getFormData()
  },
  methods: {
    async getSettlement() {
      const res = await getAction('/kt/rating/getSettle?taskId=' + this.taskDetail.id)
      if (res.code == 200) {
        if (res.data && res.data.taskSettles && res.data.taskSettles.length) {
          this.taskSettles = res.data.taskSettles
        }
      } else {
        this.$message.error(res.msg)
      }
    },
    inputChange(e, kpi) {
      kpi.value = e.target.value
    },
    async changeKpi() {
      if (this.kpiList[0].kpiValues[0].isEdit) {
        this.kpiDis = false
        const res = await postAction('/factorView/kpiToOthers', this.kpiList)
        try {
          if (res.code == 200) {
            this.$message.success('保存成功')
          } else {
            this.$message.error(res.msg || res.message)
          }
        } catch (err) {
          this.$message.error(err)
          this.spinning = false
        }
        this.getFormData(this.viewType)
      } else {
        this.kpiDis = true
      }
      let list = this.kpiList
      list.forEach(item => {
        item.kpiValues.forEach(kpi => {
          kpi.isEdit = !kpi.isEdit
        })
      })
      this.kpiList = list
      this.$forceUpdate()
    },
    async getFormData() {
      this.spinning = true
      const { productionId, chapterId } = this.params
      const beenKpiTaskId = this.taskDetail.id
      const beenKpiStageId = this.taskDetail.currentStage.id
      let draftStageId = ''
      let deliveryStageId = ''
      this.taskDetail.stageList.forEach(item => {
        if (item.stageName == '给稿') draftStageId = item.stageId
        if (item.stageName == '交稿') deliveryStageId = item.stageId
      })
      /* const res = await getAction('/factorView/kpiView', {
        beenKpiTaskId,
        beenKpiStageId,
        chapterId,
        productId: productionId,
        draftStageId,
        deliveryStageId
      })
      try {
        if (res.code == 500) {
          this.$message.error(res.msg)
          this.spinning = false
          return
        }
        this.formData = res.data
        this.spinning = false
        if (!this.kpiShow) {
          this.viewType = 2
        }
      } catch (err) {
        this.spinning = false
      } */
      const rest = await getAction('/factorView/othersKpiView', {
        kpiToTaskId: beenKpiTaskId,
        kpiToStageId: beenKpiStageId,
        chapterId,
        productId: productionId,
        draftStageId,
        deliveryStageId
      })
      try {
        if (rest.code == 200) {
          if (!this.kpiShow) {
            this.viewType = 2
          }
          rest.data.forEach(item => {
            item.kpiValues.forEach(kpi => {
              kpi.isEdit = false
            })
          })
          this.kpiList = rest.data
          this.spinning = false
        }
      } catch (err) {
        this.spinning = false
      }
      this.getSettlement()
    },
    radioChange(e) {
      this.viewType = e.target.value
      if (e.target.value == 1) {
        this.formData == null && this.getFormData(e.target.value)
      }
      if (e.target.value == 2) {
        this.kpiList.length == 0 && this.getFormData(e.target.value)
      }
    }
  }
}
</script>

<style lang="less" scoped>
::-webkit-scrollbar-button {
  height: 4px;
}
::-webkit-scrollbar {
  width: 0px;
}
.kpi-show {
  padding-right: 32%;
  &:hover {
    &::-webkit-scrollbar {
      width: 12px;
    }
  }
}
/deep/ .coef_item .ant-form-item-control-input-content {
  display: flex !important;
}
/deep/ .ant-input-number-handler-wrap {
  display: none;
}
/deep/ .ant-input-number-input-wrap {
  input {
    padding-right: 5px;
  }
}
</style>
